import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';
import { WatchAnchors } from '@strategies/react-anchors';
import ReactMobilized from '@strategies/react-mobilized';

import './App.scss';

import { ContextData, Context } from './context';
import { ENGAGEMENT_LINK, MENU, MOBILE_BREAKPOINT } from './config';
import Contact from './components/Contact/Contact';
import Welcome from './components/Welcome/Welcome';
import ArrowIcon from './assets/arrow-white.svg';
import Logo from './assets/_Monochrome.svg';
import FooterLogo from './assets/_Multicolor.svg';
import Team from './components/Team/Team';
import Goals from './components/Goals/Goals';
import Explore from './components/Explore/Explore';
import MapSequence from './components/MapSequence/MapSequence';


export default observer(function App() {
    const { bindTo } = useHenshu();
    const [data, setData] = useState<ContextData>({ map: 0, section: 0 });
    const update = useCallback((changes: ContextData) => setData({ ...data, ...changes }), [data, setData]);

    const { map, section } = data;

    const goalsLength = (bindTo('goals').get() || []).length;
    const context = { ...data, update };

    return (
        <div className={`App ${goalsLength <= map! ? 'white' : ''}`}>
            <ReactMobilized
                anchors={MENU}
                breakpoint={MOBILE_BREAKPOINT}
                logo={<></>}
                section={section! + 1}
                setCurrentSection={(n: number) => update({ section: n - 1 })}
            >
                <Context.Provider value={context}>
                    <WatchAnchors>
                        <MapSequence />
                        <Welcome />
                        <Team />
                        <Goals />
                        <Explore />
                        <Contact />
                    </WatchAnchors>
                </Context.Provider>

                <footer>
                   <button onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                        <img src={ArrowIcon} alt="Back to top" />
                    </button>
                </footer>
            </ReactMobilized>
        </div>
    );
});
