/**
 * MapSequence
 *
 * This is the map building sequence that remains fixed in the browser window while
 * the user is scrolling. As each Section passes the screens vertical midpoint,
 * the next map image is loaded into the frame.
 */

import { useEffect, useState } from 'react';
import { henshu, useHenshu } from '@strategies/henshu';
import { useHenshuApp } from '@strategies/henshu-app';

import { useContext } from '../../context';


export default function MapSequence() {
    const { bindTo } = useHenshu();
    const { isEditing, persist } = useHenshuApp();
    const { upload } = persist;
    const { map } = useContext();

    const goalsLength = (bindTo('goals').get() || []).length + 1;

    return <>
        <div className="MapSequence">
            <div className={`maps ${goalsLength - 1 < map! ? 'flip' : ''}`}>
                <henshu.each {...bindTo('goals')}>
                    {(bindToEach: any, i: number) => (
                        <div 
                            key={i} 
                            className={`map ${map! === i ? 'active' : ''}`}
                            style={{ backgroundImage: `url(${bindToEach('image').get()})` }}
                        >
                            {isEditing && (
                                <henshu.img 
                                    get={() => bindToEach('image').get()}
                                    set={async (value: string) => bindToEach('image').set(value ? await upload(value) : '')}
                                />
                            )}
                        </div>
                    )}
                </henshu.each>

                <henshu.each {...bindTo('Explore.scroller')}>
                    {(bindToEach: any, i: number) => (
                        <div 
                            key={goalsLength + i} 
                            className={`map ${map! === goalsLength + i ? 'active' : ''}`}
                            style={{ backgroundImage: `url(${bindToEach('image').get()})` }}
                        >
                            {isEditing && (
                                <henshu.img 
                                    get={() => bindToEach('image').get()}
                                    set={async (value: string) => bindToEach('image').set(value ? await upload(value) : '')}
                                />
                            )}
                        </div>
                    )}
                </henshu.each>
            </div>
        </div>
    </>;
}
