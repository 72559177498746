import { observer } from "mobx-react"
import NavSection from '../NavSection';
import Section from '../Section/Section';
import { useHenshuApp } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';


export default observer(function Explore() {
    const { isEditing, persist } = useHenshuApp();
    const { upload } = persist;
    const { bindTo } = useHenshu();

    const goalsLength = (bindTo('goals').get() || []).length + 1;

    return (
        <NavSection index={3} name="Explore" className="scroller">
            <div className="header">
                <henshu.h3 {...bindTo(`engage.Explore.subtitle`)} />
                <henshu.h2 {...bindTo(`engage.Explore.title`)} />
            </div>
            <div
                className="Explore-image--mobile"
                style={{ backgroundImage: `url(${bindTo('Explore.image.mobile').get()})` }}
            >
                <Section index={goalsLength - 1} />

                <henshu.h4 {...bindTo('Explore.sf')} />
                <henshu.h5 {...bindTo('Explore.sf.mobile.1')} />
                <henshu.h5 {...bindTo('Explore.sf.mobile.2')} />
                <henshu.h5 {...bindTo('Explore.sf.mobile.3')} />
                <henshu.h5 {...bindTo('Explore.sf.mobile.4')} />
                <henshu.h5 {...bindTo('Explore.sf.mobile.5')} />
                <henshu.h5 {...bindTo('Explore.sf.mobile.6')} />
                {isEditing && (
                    <henshu.img
                        get={() => bindTo('Explore.image.mobile').get()}
                        set={async (value: string) => bindTo('Explore.image.mobile').set(value ? await upload(value) : '')}
                    />
                )}
            </div>
            <div
                className="Explore-image"
                style={{ backgroundImage: `url(${bindTo('Explore.image').get()})` }}
            >
                <Section index={goalsLength - 1} />

                <henshu.h4 {...bindTo('Explore.sf')} />

                {isEditing && (
                    <henshu.img
                        get={() => bindTo('Explore.image').get()}
                        set={async (value: string) => bindTo('Explore.image').set(value ? await upload(value) : '')}
                    />
                )}
            </div>

            <div className="Explore-scroller">
                <henshu.each {...bindTo('Explore.scroller')}>
                    {(bindToEach, i) => (
                        <div key={i}>
                            <Section index={goalsLength + i}>
                                {(isEditing || bindToEach('title').get()) && (
                                    <henshu.h3 {...bindToEach('title')} />
                                )}
                                <henshu.richtext {...bindToEach('body')} />
                            </Section>
                        </div>
                    )}
                </henshu.each>
            </div>
        </NavSection>
    );
});
