import { observer } from "mobx-react"
import NavSection from '../NavSection';
import Section from '../Section/Section';
import { henshu, useHenshu } from '@strategies/henshu';


export default observer(function Goals() {
    const { bindTo } = useHenshu();

    return (
        <NavSection index={2} name="Goals" className="scroller">
            <henshu.each {...bindTo('goals')}>
                {(bindToEach, i) => (
                    <div key={i}>
                        <Section index={i}>
                            <henshu.h3 {...bindToEach('title')} />
                            <henshu.richtext {...bindToEach('body')} />
                        </Section>
                    </div>
                )}
            </henshu.each>
        </NavSection>
    );
});
