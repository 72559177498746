import { observer } from "mobx-react"
import NavSection from '../NavSection';
import { useHenshuApp } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';
import { useState } from "react";
import { Modal, Body, IconButton } from "@strategies/ui";
import { FiXCircle } from "react-icons/fi";




const TeamsUpdate = () => {
    const { bindTo } = useHenshu();
    return (<NavSection index={1} name="TeamUpdate">
            {/* <henshu.h3 {...bindTo('Team.update.title')} /> */}
            {/* <henshu.h2 {...bindTo('Team.update.subtitle')} /> */}
            <henshu.richtext {...bindTo('Team.update.body')} />

    </NavSection>)
}

export default observer(function Team() {
    const { bindTo } = useHenshu();
    const { isEditing, persist } = useHenshuApp();
    const { upload } = persist;
    return (
        <>
        <TeamsUpdate/>
        <NavSection index={1} name="Team">
            <henshu.richtext {...bindTo('engage.Team.body')} />

            <div className="team">
                <henshu.each {...bindTo('Team.partners')}>
                    {(bindToEach, i) => (
                        <div key={i}>
                            <TeamMember bindTo={bindToEach} />
                        </div>
                    )}
                </henshu.each>
            </div>

            <section>
                <henshu.h2 {...bindTo('Team.design.header')} />

                <div className="team">
                    <henshu.each {...bindTo('Team.design')}>
                        {(bindToEach, i) => (
                            <div key={i}>
                                <TeamMember bindTo={bindToEach} />
                            </div>
                        )}
                    </henshu.each>
                </div>
                <henshu.h2 {...bindTo('Team.partnersbanner.header')} />
                <div className="logosbanner" style={{ backgroundImage: `url(${bindTo('Team.logosbanner').get()})` }}>
                    {isEditing && (
                        <henshu.img
                            get={() => bindTo('Team.logosbanner').get()}
                            set={async (value) => bindTo('Team.logosbanner').set(value ? await upload(value) : '')}
                        />
                    )}

                </div>
            </section>


        </NavSection>
        </>
        
    );
});


const TeamMember = observer(({ bindTo }) => {
    const { isEditing } = useHenshuApp();
    const { upload } = useHenshuApp().persist;

    return (
        <div className="TeamMember">
            <div className="TeamMember-headshot" style={{ backgroundImage: `url(${bindTo('headshot').get()})` }}>
                {isEditing && (
                    <henshu.img
                        get={() => bindTo('headshot').get()}
                        set={async (value) => bindTo('headshot').set(value ? await upload(value) : '')}
                    />
                )}
            </div>

            <div className='TeamMember-content'>
                <henshu.h3 {...bindTo('name')} />
                <henshu.h4 {...bindTo('title')} />
                <henshu.richtext {...bindTo('bio')} />
            </div>
        </div>
    );
});
