/*
 * Configuration
 */

export const ENGAGEMENT_LINK = 'https://orvsp.nudge.sasaki.com';

export const HEAP_ID = '';

export const FORMSPREE_URL = 'https://formspree.io/f/xvojwnll';

export const MOBILE_BREAKPOINT = 1200;

export const MENU = [
    { text: '', href: '#' },
    { text: 'Meet The Team', href: '#team' },
    { text: 'Read The Goals', href: '#goals' },
    { text: 'Explore The Program', href: '#program' },
    { text: 'Voice Your Support', href: '#support' },
];
