import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';

import { ENGAGEMENT_LINK } from '../../config';

import NavSection from '../NavSection';
import ContactForm from '../ContactForm/ContactForm';


export default observer(function Contact() {
    const { bindTo } = useHenshu();

    return (
        <NavSection index={4} name="Contact">
           <div className="row">
           <div className="links">
                    <henshu.richtext {...bindTo('engage.Contact.body')} />
                </div>
                <ContactForm/>


            </div>
        </NavSection>
    );
});
