import { useState } from 'react';
import { observer } from 'mobx-react';
import { FiChevronDown } from 'react-icons/fi';
import { Anchor } from '@strategies/react-anchors';
import { henshu, useHenshu } from '@strategies/henshu';

import NavSection from '../NavSection';


export default observer(function Welcome() {
    const { bindTo } = useHenshu();
    const [promptHidden, setPromptHidden] = useState(false);

    return (
        <div className="Welcome-wrap">
            <NavSection name="Welcome" index={0}>
                <Anchor onChange={(anchor) => setPromptHidden(anchor.placement === 'offscreen')} />
                <div className='Welcome-bigtitle'>
                    <henshu.richtext {...bindTo('bigtitle')} />
                </div>
                <div className="metrics">
                    <henshu.each {...bindTo('Welcome.metrics')}>
                        {(bindToEach, i) => (
                            <div className="metric">
                                <henshu.h2 {...bindToEach('value')} />
                                <henshu.p {...bindToEach('metric')} />
                            </div>
                        )}
                    </henshu.each>
                </div>
            </NavSection>

            <footer
                className={promptHidden ? 'hidden' : ''}
                onClick={() => window.scrollTo({ top: window.innerHeight - 100, left: 0, behavior: 'smooth' })}
            >
                <FiChevronDown />
            </footer>
        </div>
    );
});
